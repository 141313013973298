import React, { useCallback, useRef, useState } from 'react'
import throttle from 'lodash/throttle'
import {
  DndContext,
  DragMoveEvent,
  useDndContext,
  useDndMonitor,
  useDraggable,
  Modifier,
} from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'
import { Absolute, Box, Flex, Text, Token } from '@revolut/ui-kit'

import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  DocumentsTemplateDateFieldInterface,
  DocumentsTemplateFieldInterface,
  DocumentsTemplateMoneyFieldInterface,
  DocumentsTemplatesInterface,
  DocumentsTemplateTextFieldInterface,
} from '@src/interfaces/documentsTemplates'
import { formatDate, formatMoney } from '@src/utils/format'
import { PdfPreviewScale } from '@src/features/PdfPreview/common'
import { TemplateField, fieldTypes, getFieldsByPage, getIndexedFieldKey } from './common'

type FieldAreaProps<T extends DocumentsTemplateFieldInterface> = TemplateField<T> &
  Pick<DragAndDropOverlayProps, 'scale'>

const resizeEventIdPostfix = 'resize'
const dragEventIdPostfix = 'drag'
const postfixDelimiter = '__'

const isResizeEvent = (id?: string) => id?.endsWith(resizeEventIdPostfix)
const isDragEvent = (id?: string) => id?.endsWith(dragEventIdPostfix)

const getDragEventId = (fieldId: string) =>
  `${fieldId}${postfixDelimiter}${dragEventIdPostfix}`
const getResizeEventId = (fieldId: string) =>
  `${fieldId}${postfixDelimiter}${resizeEventIdPostfix}`

const getFieldId = (id?: string) => id?.split(postfixDelimiter)[0]

const Draggable = <T extends DocumentsTemplateFieldInterface>({
  id,
  data,
  scale,
  children,
  contentBorder,
  contentBackground,
  excludeChildrenFromResizeContext,
}: React.PropsWithChildren<
  FieldAreaProps<T> & {
    contentBorder?: string
    contentBackground?: string
    excludeChildrenFromResizeContext?: boolean
  }
>) => {
  const scaled = (size: number) => size * scale.value

  const contentRef = useRef<HTMLDivElement>(null)

  const {
    active,
    setNodeRef: resizeSetNodeRef,
    listeners: resizeListeners,
    attributes: resizeAttributes,
    transform: resizeTransform,
  } = useDraggable({
    id: getResizeEventId(id),
    data: { fieldData: data, contentRef },
  })

  const {
    setNodeRef: dragSetNodeRef,
    listeners: dragListeners,
    attributes: dragAttributes,
    transform: dragTransform,
  } = useDraggable({
    id: getDragEventId(id),
    data: { fieldData: data, contentRef },
  })

  const isDragging = isDragEvent(active?.id) && getFieldId(active?.id) === id
  const isResizing = isResizeEvent(active?.id) && getFieldId(active?.id) === id

  const dragTransformX = dragTransform?.x || 0
  const dragTransformY = dragTransform?.y || 0
  const resizeTransformX = resizeTransform?.x || 0
  const resizeTransformY = resizeTransform?.y || 0

  const draggableWrapperStyle = isDragging
    ? {
        cursor: 'grabbing',
        boxShadow: `1px 1px 1px ${Token.color.greyTone20}`,
        transform: CSS.Translate.toString({
          x: -1 + dragTransformX,
          y: -1 + dragTransformY,

          scaleX: 1,
          scaleY: 1,
        }),
      }
    : {
        cursor: isResizing ? 'nwse-resize' : 'grab',
      }

  const resizeHandlerStyle = isResizing
    ? {
        transform: CSS.Translate.toString({
          x: resizeTransformX,
          y: resizeTransformY,

          scaleX: 1,
          scaleY: 1,
        }),
      }
    : {}

  const isolatedResizeContextEnabled = Boolean(
    isResizing && excludeChildrenFromResizeContext,
  )

  const getResizeOverlayDynamicHeight = useCallback(
    throttle(() => contentRef.current?.getBoundingClientRect().height, 50, {
      leading: true,
      trailing: true,
    }),
    [],
  )

  const getResizeOverlayDynamicWidth = useCallback(
    throttle(() => contentRef.current?.getBoundingClientRect().width, 50, {
      leading: true,
      trailing: true,
    }),
    [],
  )

  return (
    <Absolute
      ref={dragSetNodeRef}
      {...dragListeners}
      {...dragAttributes}
      style={draggableWrapperStyle}
      top={scaled(data.y_position)}
      left={scaled(data.x_position)}
    >
      <Absolute
        ref={resizeSetNodeRef}
        {...resizeListeners}
        {...resizeAttributes}
        style={{ cursor: 'nwse-resize', ...resizeHandlerStyle }}
        top={scaled(data.height)}
        left={scaled(data.width)}
        width={5}
        height={5}
        backgroundColor={Token.color.blue_80}
        borderRadius={Token.radius.round}
      />
      {isolatedResizeContextEnabled && (
        <Absolute
          // In case we isolate children while resizing, the container dimensions should be calculated dynamically.
          // It makes the process not so smooth compared to using transform scale css, but allows to avoid weird text
          // transformations and gives more control over the content while resizing the draggable container.
          width={getResizeOverlayDynamicWidth()}
          height={getResizeOverlayDynamicHeight()}
          style={{
            transform: CSS.Translate.toString({
              x: -1 * resizeTransformX,
              y: -1 * resizeTransformY,

              scaleX: 1,
              scaleY: 1,
            }),
          }}
        >
          {children}
        </Absolute>
      )}
      <Box
        ref={contentRef}
        width={scaled(data.width)}
        height={scaled(data.height)}
        border={contentBorder}
        bg={contentBackground}
        style={{
          transform: `scale(${
            (data.width + (2 / scale.value) * resizeTransformX) / data.width
          }, ${(data.height + (2 / scale.value) * resizeTransformY) / data.height})`,
        }}
      >
        {!isolatedResizeContextEnabled && children}
      </Box>
    </Absolute>
  )
}

const FieldPreview = <T extends DocumentsTemplateFieldInterface>(
  props: FieldAreaProps<T>,
) => {
  const { id, type, data, isActive, scale } = props

  const formatFieldCustomValue = () => {
    switch (type) {
      case 'date': {
        const fieldData = data as unknown as DocumentsTemplateDateFieldInterface
        return formatDate(fieldData.custom_value)
      }
      case 'money': {
        const fieldData = data as unknown as DocumentsTemplateMoneyFieldInterface
        return formatMoney(fieldData.custom_value, fieldData.currency?.iso_code)
      }
      default: {
        const fieldData = data as unknown as DocumentsTemplateTextFieldInterface
        return fieldData.custom_value
      }
    }
  }

  const getLabel = () => {
    switch (data.source_type.id) {
      case 'to_be_filled':
        return data.placeholder
      case 'custom_value':
        return formatFieldCustomValue()
      case 'sql_source':
        return 'Platform data'
      default:
        return 'Unknown'
    }
  }

  const isResizing = (draggableId?: string) =>
    Boolean(isResizeEvent(draggableId) && getFieldId(draggableId) === id)

  const [dynamicHeight, setDynamicHeight] = useState(data.height)

  useDndMonitor({
    onDragMove(event: DragMoveEvent) {
      if (isResizing(event.active.id)) {
        const contentRef = event.active.data.current?.contentRef.current
        if (contentRef) {
          setDynamicHeight(contentRef.getBoundingClientRect().height)
        }
      }
    },
  })

  const { active } = useDndContext()

  const currentHeight = isResizing(active?.id) ? dynamicHeight : data.height * scale.value
  const scaledFontPx = Math.floor(currentHeight * 0.7)

  return (
    <Draggable
      {...props}
      contentBorder={`1px solid ${Token.color.blue_50}`}
      contentBackground={isActive ? Token.color.blue_20 : Token.color.blue_5}
      excludeChildrenFromResizeContext
    >
      <Flex
        style={{ lineHeight: 'unset' }}
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Text fontSize={`${scaledFontPx}px`} whiteSpace="pre">
          {scaledFontPx > 2 && getLabel()}
        </Text>
      </Flex>
    </Draggable>
  )
}

type DragAndDropOverlayProps = {
  pageNum: number | undefined
  scale: PdfPreviewScale
  width: number | undefined
  height: number | undefined
  activeFieldKey: string | undefined
  setActiveFieldKey: (newKey: string | undefined) => void
}
export const DragAndDropOverlay = ({
  pageNum = 1,
  scale,
  width,
  height,
  activeFieldKey,
  setActiveFieldKey,
}: DragAndDropOverlayProps) => {
  const overlayRef = useRef<HTMLDivElement>(null)
  const positionBufferRef = useRef<{ x: number | undefined; y: number | undefined }>({
    x: undefined,
    y: undefined,
  })
  const resizeBufferRef = useRef<{
    width: number | undefined
    height: number | undefined
  }>({
    width: undefined,
    height: undefined,
  })
  const overlay = overlayRef.current
  const positionBuffer = positionBufferRef.current
  const resizeBuffer = resizeBufferRef.current

  const { values } = useLapeContext<DocumentsTemplatesInterface>()

  const restrictResize: Modifier = args => {
    const minResizeWidth = 32
    const maxResizeWidth = 512
    const minResizeHeight = 8
    const maxResizeHeight = 64

    const { active, transform } = args

    const fieldData = active?.data.current?.fieldData

    if (!isResizeEvent(active?.id) || !fieldData) {
      return transform
    }

    return {
      ...transform,
      x:
        fieldData.width + transform.x * 2 <= minResizeWidth
          ? (minResizeWidth - fieldData.width) / 2
          : fieldData.width + transform.x * 2 >= maxResizeWidth
          ? (maxResizeWidth - fieldData.width) / 2
          : transform.x,
      y:
        fieldData.height + transform.y * 2 <= minResizeHeight
          ? (minResizeHeight - fieldData.height) / 2
          : fieldData.height + transform.y * 2 >= maxResizeHeight
          ? (maxResizeHeight - fieldData.height) / 2
          : transform.y,
    }
  }

  return (
    <Absolute ref={overlayRef} width={width} height={height}>
      <DndContext
        autoScroll={{ enabled: false }}
        modifiers={[restrictToFirstScrollableAncestor, restrictResize]}
        onDragStart={e => {
          setActiveFieldKey(getFieldId(e.active.id))
        }}
        onDragMove={e => {
          const draggableContent = e.active.data.current?.contentRef.current

          if (!positionBuffer || !overlay || !draggableContent) {
            return
          }

          positionBuffer.x =
            draggableContent.getBoundingClientRect().left -
            overlay.getBoundingClientRect().left
          positionBuffer.y =
            draggableContent.getBoundingClientRect().top -
            overlay.getBoundingClientRect().top

          if (isResizeEvent(e.active.id)) {
            resizeBuffer.width = draggableContent.getBoundingClientRect().width
            resizeBuffer.height = draggableContent.getBoundingClientRect().height
          }
        }}
        onDragEnd={e => {
          const fieldData = e.active.data.current
            ?.fieldData as DocumentsTemplateFieldInterface

          if (!fieldData || !positionBuffer.x || !positionBuffer.y) {
            return
          }

          fieldData.x_position = Math.round(positionBuffer.x / scale.value)
          fieldData.y_position = Math.round(positionBuffer.y / scale.value)

          if (resizeBuffer.width && resizeBuffer.height && isResizeEvent(e.active.id)) {
            fieldData.width = Math.round(resizeBuffer.width / scale.value)
            fieldData.height = Math.round(resizeBuffer.height / scale.value)
          }
        }}
      >
        {fieldTypes.map(fieldType => (
          <React.Fragment key={fieldType}>
            {getFieldsByPage(fieldType, pageNum, values).map((fieldData, idx) => {
              const key = getIndexedFieldKey(fieldType, pageNum - 1, idx)

              return (
                <FieldPreview
                  id={key}
                  key={key}
                  type={fieldType}
                  data={fieldData}
                  scale={scale}
                  isActive={key === activeFieldKey}
                  setActive={() => setActiveFieldKey(key)}
                  setInactive={() => setActiveFieldKey(undefined)}
                />
              )
            })}
          </React.Fragment>
        ))}
      </DndContext>
    </Absolute>
  )
}
